import moment from "moment";

import { MOMENT_DATETIME_FORMAT } from "./Consts";

const mapHistoric = (historic, options) => {
  const { date, timeInitial, timeFinal } = options;

  const initial = moment(`${date} ${timeInitial}`, MOMENT_DATETIME_FORMAT);
  const final = moment(`${date} ${timeFinal}`, MOMENT_DATETIME_FORMAT);

  let positionInitial = moment(
    historic.datetime_position_initial,
    MOMENT_DATETIME_FORMAT
  );
  if (positionInitial.isBefore(initial)) {
    positionInitial = moment(initial);
  }

  let positionFinal = moment(
    historic.datetime_position_final,
    MOMENT_DATETIME_FORMAT
  );
  if (positionFinal.isAfter(final)) {
    positionFinal = moment(final);
  }
  return {
    ...historic,
    positionItem: historic.positionItem || { name: "Sem sinal" },
    datetime_position_initial: positionInitial.format(MOMENT_DATETIME_FORMAT),
    datetime_position_final: positionFinal.format(MOMENT_DATETIME_FORMAT),
    time: positionFinal.diff(positionInitial, "seconds"),
  };
};

const filterHistoric = (historic, options) => {
  if (historic.positionItem === null) return false;

  const { date, timeInitial, timeFinal } = options;

  const initial = moment(`${date} ${timeInitial}`, MOMENT_DATETIME_FORMAT);
  const final = moment(`${date} ${timeFinal}`, MOMENT_DATETIME_FORMAT);

  let positionInitial = moment(
    historic.datetime_position_initial,
    MOMENT_DATETIME_FORMAT
  );
  let positionFinal = moment(
    historic.datetime_position_final,
    MOMENT_DATETIME_FORMAT
  );

  const timeMin = 60 * 1;
  if (positionFinal.diff(positionInitial, "seconds") < timeMin) return false;
  return (
    positionInitial.isBefore(initial) ||
    positionInitial.isSame(initial) ||
    positionInitial.isBetween(initial, final) ||
    positionFinal.isSame(final) ||
    positionFinal.isBetween(initial, final) ||
    positionFinal.isAfter(final)
  );
};

const historicIsEqualLast = (current, last) => {
  const { positionItem } = current;
  const { positionItem: lastPositionItem } = last;

  return (
    current.suspension === last.suspension &&
    positionItem.name === lastPositionItem.name &&
    current.isPaused === last.isPaused
  );
};

const historicMergeWithLast = (current, last, options) => {
  if (!last.angles) {
    last.angles = [
      {
        average: current.angle,
        min: current.max_angle,
        max: current.min_angle,
      },
    ];
  } else {
    last.angles.push({
      average: current.angle,
      min: current.max_angle,
      max: current.min_angle,
    });
  }
  last.datetime_position_final = current.datetime_position_final;
  if (current.suspensionItem && last.suspensionItem) {
    last.suspensionItem.final = current.suspensionItem.final;
  }
  return mapHistoric(last, options);
};

const historicHasPosition = (item) => {
  return !item.positionItem ? false : true;
};

const historicIsValid = (historic, options) => {
  return filterHistoric(historic, options);
};

const historicHasEmptyDataBetweenNext = (current, next) => {
  const final = moment(current.datetime_position_final, MOMENT_DATETIME_FORMAT);
  const initial = moment(
    next.datetime_position_initial,
    MOMENT_DATETIME_FORMAT
  );

  const distance = initial.diff(final, "seconds");
  const limitInSeconds = 300;

  return distance > limitInSeconds;
};

const historicCreateEmptyDataBetweenNext = (current, next) => {
  const final = moment(
    current.datetime_position_final,
    MOMENT_DATETIME_FORMAT
  ).add(1, "second");
  const initial = moment(
    next.datetime_position_initial,
    MOMENT_DATETIME_FORMAT
  ).subtract(1, "second");

  return {
    datetime_position_initial: final.format(MOMENT_DATETIME_FORMAT),
    datetime_position_final: initial.format(MOMENT_DATETIME_FORMAT),
    positionItem: {
      name: "Sem sinal",
    },
  };
};

const historicOnFistItem = (historic, historics, options) => {
  const timeInitial = moment(
    `${options.date} ${options.timeInitial}`,
    MOMENT_DATETIME_FORMAT
  );
  let positionInitial = moment(
    historic.datetime_position_initial,
    MOMENT_DATETIME_FORMAT
  );

  const distance = positionInitial.diff(timeInitial, "seconds");
  const limitInSeconds = 60 * 2;

  if (distance > limitInSeconds) {
    const final = moment(positionInitial).subtract(1, "second");
    const emptyItem = {
      datetime_position_initial: timeInitial.format(MOMENT_DATETIME_FORMAT),
      datetime_position_final: final.format(MOMENT_DATETIME_FORMAT),
      positionItem: {
        name: "Sem sinal",
      },
    };

    historics.push(mapHistoric(emptyItem, options));
  }
  historic.angles = [
    {
      average: historic.angle,
      min: historic.max_angle,
      max: historic.min_angle,
    },
  ];
  historics.push(historic);
  return historics;
};

const historicOnAfterFistItem = (historic, historics, options) => {
  const lastIndex = historics.length - 1;
  let lastItem = historics[lastIndex];
  if (!historic.angles) {
    historic.angles = [
      {
        average: historic.angle,
        min: historic.max_angle,
        max: historic.min_angle,
      },
    ];
  }
  if (historicIsEqualLast(historic, lastItem)) {
    historic = historicMergeWithLast(historic, lastItem, options);
    historics[lastIndex] = historic;
  } else {
    historics.push(historic);
  }
  return historics;
};

const historicOnBeforeLastItem = (
  nextIndex,
  historic,
  historics,
  result,
  options
) => {
  let nextItem = mapHistoric(historics[nextIndex], options);

  if (
    !historicIsEqualLast(nextItem, historic) &&
    historicHasEmptyDataBetweenNext(historic, nextItem)
  ) {
    let emptyItem = mapHistoric(
      historicCreateEmptyDataBetweenNext(historic, nextItem),
      options
    );
    const limitInSeconds = 60 * 2;

    if (emptyItem.time > limitInSeconds) {
      result.push(emptyItem);
    }
  }

  return result;
};

const historicOnLastItem = (historic, historics, options) => {
  const timeFinal = moment(
    `${options.date} ${options.timeFinal}`,
    MOMENT_DATETIME_FORMAT
  );
  let positionFinal = moment(
    historic.datetime_position_final,
    MOMENT_DATETIME_FORMAT
  );

  const distance = timeFinal.diff(positionFinal, "seconds");
  const limitInSeconds = 60 * 2;

  if (distance > limitInSeconds) {
    const initial = moment(positionFinal).add(1, "second");
    const emptyItem = {
      datetime_position_initial: initial.format(MOMENT_DATETIME_FORMAT),
      datetime_position_final: timeFinal.format(MOMENT_DATETIME_FORMAT),
      positionItem: {
        name: "Sem sinal",
      },
    };

    historics.push(mapHistoric(emptyItem, options));
  }
  if (!historic.angles) {
    historic.angles = [
      {
        average: historic.angle,
        min: historic.max_angle,
        max: historic.min_angle,
      },
    ];
  }
  return historics;
};

function mergeTimesIfHistoricHasAPositionChain(arr) {
  let initialDatetime = null;
  let finalDatetime = null;
  let previousPosition = null;
  let previousPaused = null;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (previousPosition === null) {
      initialDatetime = item.datetime_position_initial;
      finalDatetime = item.datetime_position_final;
      previousPosition = item.position;
      previousPaused = item.isPaused;
    } else {
      if (
        item.position === previousPosition &&
        item.isPaused === previousPaused
      ) {
        finalDatetime = item.datetime_position_final;
      } else {
        for (
          let j = i - 1;
          j >= 0 &&
          arr[j].position === previousPosition &&
          arr[j].isPaused === previousPaused;
          j--
        ) {
          arr[j].datetime_position_initial = initialDatetime;
          arr[j].datetime_position_final = finalDatetime;
        }
        initialDatetime = item.datetime_position_initial;
        finalDatetime = item.datetime_position_final;
      }
      previousPosition = item.position;
      previousPaused = item.isPaused;
    }
  }

  // Atualiza os últimos elementos se eles formam uma série contínua
  for (
    let j = arr.length - 1;
    j >= 0 &&
    arr[j].position === previousPosition &&
    arr[j].isPaused === previousPaused;
    j--
  ) {
    arr[j].datetime_position_initial = initialDatetime;
    arr[j].datetime_position_final = finalDatetime;
  }
  return arr;
}

const mergeHistoric = (historics, options) => {
  let result = [];
  historics.forEach((item, index) => {
    if (!historicHasPosition(item)) {
      item = mapHistoric(item, options);
    }

    if (historicIsValid(item, options)) {
      if (result.length === 0) {
        historicOnFistItem(item, result, options);
      } else {
        historicOnAfterFistItem(item, result, options);
      }
      if (index + 1 === historics.length) {
        historicOnLastItem(item, result, options);
      }
    }
  });
  return mergeTimesIfHistoricHasAPositionChain(result);
};

export default function Historics() {
  const historics = this.historics
    .filter((historic) => filterHistoric(historic, this.options))
    .map((historic) => mapHistoric(historic, this.options));
  return mergeHistoric(historics, this.options);
}
