import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  ConvertRuntime,
  userWithoutPermission,
  userHasPermission,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";
import ModalBeacon from "./ModalBeacon";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";
import ModalWithdrawPatient from "../../../../../components/Modals/ModalWithdrawPatient";
import VerifyPwWithUser from "../../../../../components/Modals/VerifyPwWithUser";

var originalRoom = null;
var blockId = false;

//Pra saber se a comorbidade removida estava salva
var originalComor = null;
var originalSituationalStates = [];
var deleteComor = [];
var lastComor = [];
var lastSituationalState = [];

function Informacoes() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);

  const [showPw, setShowPw] = useState(false);
  const [showPwWithUser, setShowPwWithUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [loadingBtnActivate, setLoadingBtnActivate] = useState("none");
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });

  //Info paciente
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [room, setRoom] = useState({ label: "Selecionar", value: 0 });
  const [subroom, setSubroom] = useState({ label: "Selecionar", value: 0 });
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [imc, setImc] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [age, setAge] = useState("-");
  const [bed, setBed] = useState({ label: "Selecionar superfície", value: 0 });
  const [comorbidities, setComorbidities] = useState([]);
  const [situationalStates, setSituationalStates] = useState([]);
  const [admissionWound, setAdmissionWound] = useState(null);

  const [listRoom, setListRoom] = useState([]);
  const [listSubroom, setListSubroom] = useState([]);
  const [hasSubroom, setHasSubroom] = useState(false);

  const [listBeds, setListBeds] = useState([]);
  const [otherBed, setOtherBed] = useState("");

  const [listComorbidities, setListComorbidities] = useState([]);
  const [listSituationalStates, setListSituationalStates] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [patient, setPatient] = useState({});
  const [reload, setReload] = useState(false);

  const [hasOther, setHasOther] = useState(false);
  const [hasOtherSituationalState, setHasOtherSituationalState] =
    useState(false);
  const [newComorbiditie, setNewComobiditie] = useState("");
  const [newSituationalState, setNewSituationalState] = useState("");

  const [showModalBeacon, setShowModalBeacon] = useState(false);
  const [showModalWithDraw, setShowModalWithdraw] = useState(false);

  const [suspendTime, setSuspendTime] = useState(null);
  const [updateTime, setUpdateTime] = useState(false);

  useEffect(() => {
    function LoadPatient() {
      api
        .get(`patients/${patientSelected.dados.id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatient success");
            console.log(response.data);

            setPatient(response.data);
            setSuspendTime(
              moment
                .utc(response.data.time_suspend_left * 1000)
                .format("HH:mm:ss")
            );

            if (response.data.number_identifier != null) {
              setId(response.data.number_identifier);
              blockId = true;
            } else {
              blockId = false;
            }

            setAdmissionWound(response.data.admission_wound);
            setName(response.data.name);
            setGender(response.data.gender);

            //If é pq tem quarto e leito
            //Else tem só o quarto
            if (
              response.data.subroom != null &&
              response.data.subroom.room_id > 0
            ) {
              setRoom({
                label: response.data.subroom.room.name,
                value: response.data.subroom.room.id,
              });
              setSubroom({
                label: response.data.subroom.name,
                value: response.data.subroom.id,
              });
            } else if (response.data.subroom != null) {
              setRoom({
                label: response.data.subroom.name,
                value: response.data.subroom.id,
              });
            }

            if (response.data.height != null) {
              setHeight(
                String(
                  parseFloat(response.data.height).toFixed(2).replace(".", ",")
                )
              );
            }
            if (response.data.weight != null) {
              setWeight(
                String(
                  parseFloat(response.data.weight).toFixed(2).replace(".", ",")
                )
              );
            }
            if (response.data.imc != null) {
              setImc(
                String(
                  parseFloat(response.data.imc).toFixed(2).replace(".", ",")
                )
              );
            }
            setDateBirth(moment(response.data.date_birth).format("DD/MM/YYYY"));
            setAge(
              CalculateAge(
                moment(response.data.date_birth).format("DD/MM/YYYY")
              )
            );

            if (response.data.type_bed != null) {
              setBed({
                label: response.data.type_bed.name,
                value: response.data.type_bed_id,
              });
            }

            var arrayComor = [];
            var arraySituationalStates = [];
            response.data.comorbidities.map((item) => {
              var obj = {};
              obj.label = item.comorbidity.name;
              obj.value = item.comorbidity.id;
              obj.idRelation = item.id;
              arrayComor.push(obj);
            });
            response.data.situational_states.map((item) => {
              var obj = {};
              obj.label = item.comorbidity.name;
              obj.value = item.comorbidity.id;
              obj.idRelation = item.id;
              arraySituationalStates.push(obj);
            });
            originalComor = arrayComor;
            originalSituationalStates = arraySituationalStates;
            setComorbidities(arrayComor);
            setSituationalStates(arraySituationalStates);

            //Chama function pra carregar lista de room e subroom
            if (
              response.data.subroom != null &&
              response.data.subroom.room_id > 0
            ) {
              LoadRoom(
                response.data.unit_id,
                response.data.subroom.room.id,
                response.data.subroom.id
              );
            } else if (response.data.subroom != null) {
              LoadRoom(response.data.unit_id, response.data.subroom.id, null);
            } else {
              LoadRoom(response.data.unit_id, null, null);
            }
          }
        })
        .catch((error) => {
          console.log("LoadPatient " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as informações do paciente.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadPatient();
  }, [reload]);

  useEffect(() => {
    if (!userWithoutPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
    deleteComor = [];
  }, []);

  useEffect(() => {
    if (moment.duration(suspendTime).asMinutes() <= 1440) {
      setSuspendTime(
        moment(suspendTime, "HH:mm:ss")
          .subtract(1, "seconds")
          .format("HH:mm:ss")
      );
    }

    setTimeout(() => {
      setUpdateTime(!updateTime);
    }, 1000);
  }, [updateTime]);

  function LoadRoom(unit_id, room_id, subroom_id) {
    api
      .get(`/units/${unit_id}/rooms`)
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadRoom");
          console.log(response.data);

          var array = [];
          var arrayOriginal = [];
          var arraySubroom = [];
          response.data.map((item) => {
            if (!item.has_patient || item.id == room_id) {
              var obj = {};
              obj.id = item.id;
              obj.name = item.name;
              obj.room_id = item.room_id;
              obj.unit_id = item.unit_id;
              obj.required = item.required;

              if (item.id == room_id) {
                setHasSubroom(item.required);
              }

              var arraySb = [];
              item.subrooms.map((i) => {
                if (!i.has_patient || i.id == subroom_id) {
                  if (i.room_id == room_id) {
                    arraySubroom.push({ label: i.name, value: i.id });
                  }

                  arraySb.push(i);
                }
              });
              obj.subrooms = arraySb;
              arrayOriginal.push(obj);
              array.push({ label: item.name, value: item.id });
            }
          });

          originalRoom = arrayOriginal;
          setListSubroom(arraySubroom);
          setListRoom(array);
        }
      })
      .catch((error) => {
        console.log("LoadRoom error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível carregar os quartos e leitos. Verifique sua conexão.",
          })
        );
      });
  }

  useEffect(() => {
    function LoadBeds() {
      api
        .get(`/typebeds`, {
          params: {
            patient_id: patientSelected.dados.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadBeds");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListBeds(array);
          }
        })
        .catch((error) => {
          console.log("LoadBeds error " + error);
        });
    }

    LoadBeds();
  }, []);

  useEffect(() => {
    function LoadComorbidities() {
      api
        .get(`/comorbidities`, {
          params: {
            patient_id: patientSelected.dados.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadComorbidities");
            console.log(response.data);

            const comorbiditiesArray = [];
            const situationalStatesArray = [];
            response.data.comorbidities.map((item) => {
              comorbiditiesArray.push({ label: item.name, value: item.id });
            });
            response.data.situationalStates.map((item) => {
              situationalStatesArray.push({ label: item.name, value: item.id });
            });
            setListComorbidities(comorbiditiesArray);
            setListSituationalStates(situationalStatesArray);
          }
        })
        .catch((error) => {
          console.log("LoadComorbidities error " + error);
        });
    }

    LoadComorbidities();
  }, []);

  function HandlerRoom(room) {
    setSubroom({ label: "Selecionar", value: 0 });
    setRoom(room);

    var room = originalRoom.find((item) => item.id == room.value);
    setHasSubroom(room.required);

    var arraySb = [];
    room.subrooms.map((item) => {
      arraySb.push({ label: item.name, value: item.id });
    });

    setListSubroom(arraySb);
  }

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  function DeleteComor() {
    deleteComor.map((item) => {
      api
        .delete(
          `/patients/${patientSelected.dados.id}/comorbidities/${item.idRelation}`
        )
        .then((response) => {
          if (response.status == 204) {
            console.log("DeleteComor success");
            // var newList = originalComor.filter((i) => i.value != item.value);
            // originalComor = newList;
          }
        })
        .catch((error) => {
          console.log("DeleteComor error " + error);
          // setListComorbidities([...comorbidities, item]);
          // dispatch(
          //   Show_Alert({
          //     type: "error",
          //     msg:
          //       "Não foi possível deletar essa comorbidade. Tente novamente mais tarde",
          //   })
          // );
        });
    });
  }

  function VerifyFields() {
    let verify = false;

    if (!hasSubroom) {
      if (name != "" && room.value > 0 && subroom.value > 0 && gender != "") {
        verify = true;
      }
    } else {
      if (name != "" && room.value > 0) {
        verify = true;
      }
    }

    return verify;
  }

  function EditPatient(userId) {
    setLoadingBtn("flex");
    DeleteComor(); //Deleta as comorbidades removidas

    var arrayComorbidities = [];
    comorbidities.map((item) => {
      if (item.is_other) {
        arrayComorbidities.push({ name: item.label, is_situational: false });
        return;
      }
      if (item.idRelation == undefined) {
        var obj = {};
        obj.comorbidity_id = item.value;
        arrayComorbidities.push(obj);
      }
    });
    situationalStates.map((item) => {
      if (item.is_other) {
        arrayComorbidities.push({ name: item.label, is_situational: true });
        return;
      }

      if (item.idRelation == undefined) {
        var obj = {};
        obj.comorbidity_id = item.value;
        arrayComorbidities.push(obj);
      }
    });

    if (newComorbiditie != "") {
      arrayComorbidities.push({ name: newComorbiditie, is_situational: false });
    }
    if (newSituationalState != "") {
      arrayComorbidities.push({
        name: newSituationalState,
        is_situational: true,
      });
    }

    var weightConvert = weight;
    if (weight.length == 6) {
      var parts = weight.split("");
      if (parts[2] == ",") {
        weightConvert = `${parts[0]}${parts[1]}${parts[3]},${parts[4]}${parts[5]}`;
      }
    }

    // console.log("Name " + name);
    // console.log("number_identifier " + id);
    // console.log("gender " + gender);
    // console.log(
    //   "date_birth " + moment(dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD")
    // );
    // console.log("age " + age.split(" ")[0]);
    // // console.log("unit_id " + unit.selecionado.id);
    // console.log(
    //   "height " + height != "" ? parseFloat(height.replace(",", ".")) : ""
    // );
    // console.log(
    //   "weight " + weightConvert != ""
    //     ? parseFloat(weightConvert.replace(",", "."))
    //     : ""
    // );
    // console.log("imc " + imc);
    // console.log("room_id " + subroom > 0 ? subroom : room);
    // console.log("type_bed_id " + bed.value);
    // console.log("user_id " + userChooser.value);
    // console.log("comorbidities ");
    // console.log(arrayComorbidities);
    // console.log("admission_wound " + admissionWound);

    api
      .put(`/patients/${patientSelected.dados.id}`, {
        name,
        number_identifier: id,
        gender,
        date_birth: moment(dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD"),
        age: age.split(" ")[0],
        unit_id: patientSelected.dados.unit_id,
        height: height != "" ? parseFloat(height.replace(",", ".")) : "",
        weight:
          weightConvert != ""
            ? parseFloat(weightConvert.replace(",", "."))
            : "",
        imc: imc != "" ? imc.replace(",", ".") : "",
        room_id: subroom.value > 0 ? subroom.value : room.value,
        type_bed_id: bed.value > 0 ? bed.value : "",
        type_bed: otherBed,
        user_id: userId ? userId : userChooser.value,
        comorbidities: arrayComorbidities,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Edit with success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Paciente editado com sucesso",
            })
          );

          setHasOther(false);
          setHasOtherSituationalState(false);
          setNewComobiditie("");
          setNewSituationalState("");
          setReload(!reload);

          //Atualiza o name do paciente no topo esquerdo
          var patient = patientSelected.dados;
          patient.name = response.data.name;
          dispatch({ type: "SET_PATIENT_SELECTED", dados: patient });
        }
      })
      .catch((error) => {
        console.log("Edit error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível editar esse paciente. Tente novamente mais tarde",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        lastComor = [];
        lastSituationalState = [];
      });
  }

  // console.log("deleteComor array");
  // console.log(deleteComor);

  // console.log("originalComor array");
  // console.log(originalComor);

  function FilterOther(list) {
    return list.filter((l) => l.label != "Outros");
  }

  function RenderTimeSuspend() {
    if (!patient.time_suspend_left) {
      return "-";
    }
    if (patient.time_suspend_left <= 0) {
      return "00:00:00";
    }
    return suspendTime;
  }

  function Reactivate(userId) {
    var id = userId;
    if (userId == undefined) {
      id = user.dados.id;
    }

    api
      .get(`/patients/${patient.id}/reactivate_notifications`, {
        params: { userId: id },
      })
      .then((response) => {
        if (response.status == 200) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Avisos reativados",
            })
          );
          setReload(!reload);
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
      });
  }

  return (
    <div>
      <div className="d-flex align-items-center title-patient mb-4">
        <h2 className="screen-name mb-4">Informações do paciente</h2>
        {patient.status !== 0 && (
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => setShowModalWithdraw(true)}
          >
            <Icon color={Colors.brand_pink} size={20} icon="user-check" />
            <p
              className="mb-0 ml-2"
              style={{
                color: Colors.brand_pink,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Dar alta
            </p>
          </button>
        )}
      </div>

      {showPwWithUser && (
        <VerifyPwWithUser
          handler_show_pw={setShowPwWithUser}
          show_pw={showPwWithUser}
          handler_request={Reactivate}
        />
      )}

      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar confirmação"}
          handler_request={EditPatient}
        />
      )}

      {showModalBeacon && (
        <ModalBeacon
          handler_show_modal={setShowModalBeacon}
          show_modal={showModalBeacon}
          handler_reload={setReload}
          reload={reload}
          link={patient.beacon_id == 0 ? true : false}
          patient_name={patient.name}
          beacon_name={patient?.beacon?.name}
        />
      )}

      {showModalWithDraw && (
        <ModalWithdrawPatient
          handler_modal={setShowModalWithdraw}
          show_modal={showModalWithDraw}
          handler_reload={setReload}
          reload={reload}
          notification_id={patient.notification_id}
          patient_name={patient.name}
          patient_id={patient.id}
          room={patient.subroom.name}
          subroom={patient.subroom?.room?.name}
          is_edit={patient.datetime_discharge_hospital}
        />
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && patient.status == 0 && (
        <Row className="card-discharge mb-3 mx-0 p-2 py-3">
          <Col xs={12}>
            <div>
              <div className="d-flex justify-content-between">
                <div className="tag-discharge">
                  <p className="text-uppercase">Paciente de alta</p>
                </div>
                <button
                  className="btn-modal-suspend-mdd"
                  onClick={() => {
                    setShowModalWithdraw(true);
                  }}
                >
                  <Icon color={Colors.brand_pink} size={20} icon="edit" />
                  <p className="ml-2">Editar motivo de alta</p>
                </button>
              </div>
              <div
                className="card-data-sm mt-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="enter"
                  className="mr-2"
                />
                <div>
                  <p>{patient.motive_discharge_hospital.name}</p>
                  <p>
                    {patient.other_motive_discharge_hospital !== null &&
                      patient.other_motive_discharge_hospital}
                  </p>
                </div>
              </div>

              {/* <div
                className="card-data-sm mt-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="band-aid"
                  className="mr-2"
                />
                {patient.has_wound == "true" ? "Com lesão" : "Sem lesão"}
              </div> */}

              <div
                className="mt-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div className="c-profile-dropdown mr-2">
                  {renderPhoto(
                    makeUserDataToPhoto(
                      patient.discharge_hospital_user.name,
                      patient.discharge_hospital_user.image !== null
                        ? patient.discharge_hospital_user.url_image
                        : null
                    )
                  )}
                </div>

                <div className="dados-default">
                  <div className="name-default">
                    Por {patient.discharge_hospital_user.name}
                  </div>
                  <div className="date-default">
                    {moment(patient.datetime_discharge_hospital).format("LLL")}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {/* <Col xs={12} md={6} lg={3}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() => ActivatePatient()}
                className="default-btn"
                style={{ maxWidth: 190 }}
              >
                <p
                  className="mb-0"
                  style={{
                    display: loadingBtnActivate == "none" ? "flex" : "none",
                  }}
                >
                  Ativar novamente
                </p>

                <Spinner
                  style={{ display: loadingBtnActivate }}
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              </button>
            </div>
          </Col> */}
        </Row>
      )}

      {!loading && patient.suspend != null && patient.status != 0 && (
        <Row className="card-discharge mb-3 mx-0 p-2 py-3">
          <Col xs={12}>
            <Row
              className="justify-content-between"
              style={{
                paddingLeft: "11px",
              }}
            >
              <div
                className="tag-discharge"
                style={{ backgroundColor: "#ffc800" }}
              >
                <p className="text-uppercase">Avisos suspensos</p>
              </div>
              <button
                className="btn-modal-suspend-mdd"
                onClick={() => {
                  if (userHasPermission(user)) {
                    Reactivate();
                    return false;
                  }
                  setShowPwWithUser(true);
                }}
              >
                <Icon color={Colors.brand_pink} size={20} icon="bell" />
                <p className="ml-2">Reativar avisos</p>
              </button>
            </Row>

            <div>
              <div className="card-data-sm mt-3 mb-3">
                <Row>
                  <Col xl={4} md={4} lg={4} sm={12} className="suspension-info">
                    <p className="title-patient-suspension-info">
                      Tempo de suspensão
                    </p>
                    <div>
                      <Icon
                        color={Colors.brand_blue}
                        size={20}
                        icon="clock-arrow-up"
                        className="mr-2"
                      />
                      {patient.suspend.time_minutes !== "INDETERMINATE" ? (
                        <span>
                          {ConvertRuntime(patient.suspend.time_minutes)}
                        </span>
                      ) : (
                        <span>Suspenso por tempo indeterminado</span>
                      )}
                    </div>
                  </Col>
                  {patient.suspend.time_minutes !== "INDETERMINATE" && (
                    <Col
                      xl={4}
                      md={4}
                      lg={4}
                      sm={12}
                      className="suspension-info"
                    >
                      <p className="title-patient-suspension-info">
                        Tempo restante da suspensão
                      </p>
                      <div>
                        <Icon
                          color={Colors.brand_blue}
                          size={20}
                          icon="clock"
                          className="mr-2"
                        />
                        <span className="patient-suspension-info-timer">
                          {RenderTimeSuspend()}
                        </span>
                      </div>
                    </Col>
                  )}
                  <Col xl={4} md={4} lg={4} sm={12} className="suspension-info">
                    <p className="title-patient-suspension-info">Motivo</p>
                    <p>{patient.suspend.patientMotive.motive.name}</p>
                    <p>{patient.suspend.patientMotive.other_motive}</p>
                  </Col>
                </Row>
              </div>

              <div
                className="mt-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 30, height: 30, borderRadius: "100%" }}
                  src={
                    patient.suspend.user.image != null
                      ? patient.suspend.user.url_image
                      : require("../../../../../assets/img/icon_user.png")
                  }
                />

                <div className="dados-default">
                  <div className="name-default">
                    Por {patient.suspend.user.name}
                  </div>
                  <div className="date-default">
                    {moment(patient.suspend.created_at).format("LLL")}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {!loading && (
        <div>
          <>
            <div className="mb-4">
              <Form.Label className="field-label">Atendimento</Form.Label>
              <input
                disabled={blockId != false ? true : false}
                style={{
                  backgroundColor: blockId == false ? "white" : "#F5F6F9",
                  color: blockId == false ? "black" : Colors.dark_gray,
                }}
                className="default-input"
                type="text"
                placeholder="Digite o atendimento do paciente"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <Form.Label className="field-label">
                Nome
                {process.env.REACT_APP_HOSPITAL == "HSL" ? " (iniciais) " : " "}
                *
              </Form.Label>
              <input
                className="default-input"
                type="text"
                placeholder={
                  process.env.REACT_APP_HOSPITAL == "HSL"
                    ? "Digite as iniciais do nome do paciente"
                    : "Digite o nome do paciente"
                }
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {patient.status !== 0 && hospitalPlan.dados.type === 1 && (
              <div className="mb-4">
                <Form.Label className="field-label">Sensor</Form.Label>
                <div className="container-sensor">
                  <div className="d-flex w-50">
                    <Icon
                      color={
                        patient.beacon_id == 0
                          ? Colors.dark_gray
                          : Colors.brand_blue
                      }
                      size={24}
                      icon="preveni-icon"
                      className="mr-2"
                    />
                    <p
                      className={
                        patient.beacon_id == 0
                          ? "text-vincular"
                          : "text-desvincular"
                      }
                    >
                      {patient.beacon_id == 0
                        ? "Não vinculado"
                        : patient.beacon.name}
                    </p>
                  </div>
                  <button
                    disabled={patient?.score >= 19}
                    onClick={() => setShowModalBeacon(true)}
                    className=" modal-footer-btn btn-confirm"
                    style={{
                      backgroundColor:
                        patient?.score >= 19
                          ? Colors.lighter_gray
                          : patient.beacon_id == 0
                          ? Colors.brand_pink
                          : Colors.dark_gray,
                      width: "50%",
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{
                        color:
                          patient?.score >= 19 ? Colors.dark_gray : "white",
                      }}
                    >
                      {patient.beacon_id == 0 ? "Vincular" : "Desvincular"}
                    </p>
                  </button>
                </div>
              </div>
            )}

            <div>
              <Form.Label className="field-label w-100">Sexo</Form.Label>
              <button
                style={{
                  background:
                    gender == "M" ? Colors.brand_blue : Colors.lightest_gray,
                }}
                className="btn-click"
                onClick={() => setGender("M")}
              >
                <p
                  className="mb-0"
                  style={{
                    color: gender == "M" ? "white" : Colors.darker_gray,
                  }}
                >
                  Masculino
                </p>
              </button>

              <button
                style={{
                  background:
                    gender == "F" ? Colors.brand_blue : Colors.lightest_gray,
                }}
                className="btn-click"
                onClick={() => setGender("F")}
              >
                <p
                  className="mb-0"
                  style={{
                    color: gender == "F" ? "white" : Colors.darker_gray,
                  }}
                >
                  Feminino
                </p>
              </button>
            </div>

            <Row className="mt-2">
              <Col className="mb-3 mb-md-0" col={12} md={6}>
                <Form.Label className="field-label">Quarto *</Form.Label>
                <Select
                  captureMenuScroll={false}
                  isSearchable={false}
                  noOptionsMessage={() => "Indisponível"}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listRoom}
                  isClearable={false}
                  value={room}
                  onChange={(item) => HandlerRoom(item)}
                />
              </Col>

              {!hasSubroom && (
                <Col className="mb-3 mb-md-0" col={12} md={6}>
                  <Form.Label className="field-label">Leito *</Form.Label>
                  <Select
                    captureMenuScroll={false}
                    isSearchable={false}
                    noOptionsMessage={() => "Indisponível"}
                    classNamePrefix="react-select"
                    placeholder={"Selecionar"}
                    options={listSubroom}
                    isClearable={false}
                    value={subroom}
                    onChange={(item) => setSubroom(item)}
                  />
                </Col>
              )}
            </Row>

            <div className="mt-4">
              <Form.Label className="field-label w-100">
                Admitido com lesão por pressão?
              </Form.Label>
              <button
                disabled={true}
                style={{
                  background:
                    admissionWound == 1
                      ? Colors.brand_blue
                      : Colors.lightest_gray,
                  width: 100,
                }}
                className="btn-click"
                onClick={() => setAdmissionWound(1)}
              >
                <p
                  className="mb-0"
                  style={{
                    color: admissionWound == 1 ? "white" : Colors.darker_gray,
                  }}
                >
                  Sim
                </p>
              </button>

              <button
                disabled={true}
                style={{
                  background:
                    admissionWound == 0
                      ? Colors.brand_blue
                      : Colors.lightest_gray,
                  width: 100,
                }}
                className="btn-click"
                onClick={() => setAdmissionWound(0)}
              >
                <p
                  className="mb-0"
                  style={{
                    color: admissionWound == 0 ? "white" : Colors.darker_gray,
                  }}
                >
                  Não
                </p>
              </button>
            </div>

            <Row className="mt-4">
              <Col xs={12} md={3}>
                <Form.Label className="field-label">Peso (kg)</Form.Label>
                <InputMask
                  mask={weight.length <= 5 ? "99,999" : "999,99"}
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="00,00"
                  value={weight}
                  onChange={(e) => {
                    setWeight(e.target.value);
                    setImc(GetImc(e.target.value, height));
                  }}
                />
              </Col>

              <Col xs={12} md={3}>
                <Form.Label className="field-label">Altura (m)</Form.Label>
                <InputMask
                  mask="9,99"
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="0,00"
                  value={height}
                  onChange={(e) => {
                    setHeight(e.target.value);
                    setImc(GetImc(weight, e.target.value));
                  }}
                />
              </Col>

              <Col xs={12} md={2}>
                <Form.Label className="field-label">IMC</Form.Label>
                <InputMask
                  disabled={true}
                  mask="99,99"
                  maskChar={null}
                  style={{
                    background: "#468DFF1A",
                    color: Colors.brand_blue,
                    border: "none",
                    fontWeight: "bold",
                  }}
                  className="default-input text-center input-imc"
                  type="text"
                  placeholder="00,00"
                  value={imc}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} md={6}>
                <Form.Label className="field-label">
                  Data de nascimento
                </Form.Label>
                <div className="d-flex align-items-center default-input">
                  <Icon
                    color={Colors.darker_gray}
                    size={24}
                    icon="calendar"
                    className="mr-2"
                  />
                  <InputMask
                    mask={"99/99/9999"}
                    maskChar={null}
                    style={{ border: "none" }}
                    type="text"
                    placeholder="DD/MM/AAAA"
                    value={dateBirth}
                    onChange={(e) => {
                      let dateBirth = e.target.value;
                      setDateBirth(dateBirth);
                      if (dateBirth.length == 10) {
                        if (!CalculateAge(dateBirth)) {
                          alert("Data inválida");
                          setDateBirth("");
                          return false;
                        }
                        setAge(CalculateAge(dateBirth));
                      } else {
                        setAge("-");
                      }
                    }}
                  />
                </div>
              </Col>

              <Col xs={12} md={2}>
                <Form.Label className="field-label">Idade</Form.Label>
                <input
                  disabled={true}
                  style={{
                    background: "#468DFF1A",
                    color: Colors.brand_blue,
                    border: "none",
                    fontWeight: "bold",
                  }}
                  className="default-input text-center"
                  type="text"
                  placeholder="-"
                  value={age}
                />
              </Col>
            </Row>

            <div className="mt-4">
              <Form.Label className="field-label">
                Superfície de suporte
              </Form.Label>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder={"Selecionar superfície"}
                options={listBeds}
                isClearable={false}
                value={bed}
                onChange={(item) => setBed(item)}
              />

              {bed.value == 6 && (
                <input
                  className="default-input mt-3"
                  type="text"
                  placeholder="Digite aqui o tipo da superfície de suporte"
                  value={otherBed}
                  onChange={(e) => setOtherBed(e.target.value)}
                />
              )}
            </div>

            <div className="mt-4">
              <Form.Label className="field-label">Comorbidades</Form.Label>
              <Select
                captureMenuScroll={false}
                menuPlacement={"top"}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder="Selecionar comorbidades"
                closeMenuOnSelect={false}
                options={listComorbidities}
                isMulti
                styles={colourStyles}
                isClearable={false}
                value={FilterOther(comorbidities)}
                onChange={(array) => {
                  //Verifica se o removido já estava salvo
                  //Se sim da push no array pra deletar na hora de salvar

                  if (array != null) {
                    if (array.length == 1) {
                      lastComor.push(array[0]);
                    }
                    originalComor.map((item) => {
                      var found = array.find((i) => i.value == item.value);
                      if (found == undefined && item.idRelation != undefined) {
                        var foundDelete = deleteComor.find(
                          (d) => d.value == item.value
                        );
                        if (foundDelete == undefined) deleteComor.push(item);
                      }
                    });

                    //Mostra o input caso marque a opção "Outros"
                    var found = array.find((item) => item.label == "Outros");
                    if (found != undefined) setHasOther(true);
                    else setHasOther(false);
                  } else {
                    //Adiciona a ultima comorbiditie no array pra deletar
                    if (lastComor.length > 0) {
                      deleteComor.push(lastComor[0]);
                    } else {
                      if (originalComor.length > 0) {
                        deleteComor.push(originalComor[0]);
                      }
                    }
                  }

                  setComorbidities(
                    array == null
                      ? []
                      : array.filter((i) => i.label !== "Outros")
                  );
                }}
              />

              {hasOther && (
                <input
                  className="default-input mt-3"
                  type="text"
                  placeholder="Digite aqui as comorbidades (separe por ;)"
                  value={newComorbiditie}
                  onChange={(e) => {
                    setNewComobiditie(e.target.value);
                    if (
                      e.target.value.includes(";") &&
                      e.target.value.length > 1
                    ) {
                      if (
                        !comorbidities.find(
                          (i) => i.label == newComorbiditie
                        ) &&
                        !situationalStates.find(
                          (i) => i.label == newComorbiditie
                        )
                      ) {
                        setComorbidities([
                          ...comorbidities,
                          {
                            is_other: true,
                            label: newComorbiditie,
                            value: Date.now(),
                          },
                        ]);
                      }
                      setNewComobiditie("");
                    }
                  }}
                />
              )}
            </div>
            <div className="mt-4">
              <Form.Label className="field-label">
                Estados situacionais
              </Form.Label>
              <Select
                captureMenuScroll={false}
                menuPlacement={"top"}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder="Selecionar estados situacionais"
                closeMenuOnSelect={false}
                options={listSituationalStates}
                isMulti
                styles={colourStyles}
                isClearable={false}
                value={FilterOther(situationalStates)}
                onChange={(array) => {
                  if (array != null) {
                    if (array.length == 1) {
                      lastSituationalState.push(array[0]);
                    }
                    originalSituationalStates.map((item) => {
                      var found = array.find((i) => i.value == item.value);
                      if (found == undefined && item.idRelation != undefined) {
                        var foundDelete = deleteComor.find(
                          (d) => d.value == item.value
                        );
                        if (foundDelete == undefined) deleteComor.push(item);
                      }
                    });
                    var found = array.find((item) => item.label == "Outros");
                    if (found != undefined) setHasOtherSituationalState(true);
                    else setHasOtherSituationalState(false);
                  } else {
                    if (lastSituationalState.length > 0) {
                      deleteComor.push(lastSituationalState[0]);
                    } else {
                      if (originalSituationalStates.length > 0) {
                        deleteComor.push(originalSituationalStates[0]);
                      }
                    }
                  }
                  setSituationalStates(
                    array == null
                      ? []
                      : array.filter((i) => i.label !== "Outros")
                  );
                }}
              />

              {hasOtherSituationalState && (
                <input
                  className="default-input mt-3"
                  type="text"
                  placeholder="Digite aqui os estados situacionais (separe por ;)"
                  value={newSituationalState}
                  onChange={(e) => {
                    setNewSituationalState(e.target.value);
                    if (
                      e.target.value.includes(";") &&
                      e.target.value.length > 1
                    ) {
                      if (
                        !comorbidities.find(
                          (i) => i.label == newSituationalState
                        ) &&
                        !situationalStates.find(
                          (i) => i.label == newSituationalState
                        )
                      ) {
                        setSituationalStates([
                          ...situationalStates,
                          {
                            is_other: true,
                            label: newSituationalState,
                            value: Date.now(),
                          },
                        ]);
                      }
                      setNewSituationalState("");
                    }
                  }}
                />
              )}
            </div>
          </>

          <div className="mt-5">
            <button
              onClick={() => {
                if (!userWithoutPermission(user)) {
                  EditPatient();
                  return;
                } else {
                  setShowPw(true);
                }
              }}
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className=" modal-footer-btn btn-confirm float-right"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
                width: 100,
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Informacoes;
