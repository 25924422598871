import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Colors from "../../../../../../../styles/Colors";
import {
  resizeChartBasedOnWidth,
  createStripePattern,
} from "../../../../../../../functions/utils";

function adjustColorOpacity(color, opacity) {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function SensorPrescriptionTimeTwoBar(props) {
  const { schedules, historics } = props;

  const [labels, setLabels] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [sensorDataPaused, setSensorDataPaused] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);

  const secondsToFullHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    return `${hours.toString().padStart(2, "0")}:00`;
  };

  useEffect(() => {
    if (schedules && historics) {
      const labels = [
        ...new Set([
          ...schedules.map((item) => item.position),
          ...historics.positionTime
            .filter(
              (i) =>
                i.position !== "Sem sinal" && i.position !== "Tempo Pausado"
            )
            .map((item) => item.position),
        ]),
      ];

      const arr1Values = new Array(labels.length).fill(0);
      const arr1ValuesPaused = new Array(labels.length).fill(0);
      const arr2Values = new Array(labels.length).fill(0);

      historics.positionTime.forEach((item) => {
        const index = labels.indexOf(item.position);
        if (index !== -1) {
          arr1Values[index] = item.time;
          arr1ValuesPaused[index] = item.timePaused;
        }
      });

      schedules.forEach((item) => {
        const index = labels.indexOf(item.position);
        if (index !== -1) {
          arr2Values[index] = item.time;
        }
      });

      setLabels(labels);
      setSensorData(arr1Values);
      console.log(arr1ValuesPaused);
      setSensorDataPaused(arr1ValuesPaused);
      setScheduleData(arr2Values);
    }
  }, [schedules, historics]);

  const showSensorPaused = sensorDataPaused.some((value) => value > 3600);

  const datasets = [
    {
      label: "Prescrição",
      data: scheduleData,
      backgroundColor: Colors.brand_blue,
      barPercentage: 0.5,
      stack: "Stack 0",
    },
    {
      label: "Sensor",
      data: sensorData,
      backgroundColor: Colors.brand_pink,
      barPercentage: 0.5,
      stack: "Stack 1",
    },
  ];

  if (showSensorPaused) {
    datasets.push({
      label: "Sensor Pausado",
      data: sensorDataPaused,
      backgroundColor: function (context) {
        return createStripePattern(Colors.brand_pink + "c2");
      },
      barPercentage: 0.5,
      stack: "Stack 1",
    });
  }

  return (
    <Bar
      height={resizeChartBasedOnWidth()}
      data={{
        labels: labels,
        datasets: datasets,
      }}
      options={{
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (val) => secondsToFullHours(val),
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              const datasetLabel =
                data.datasets[tooltipItem[0].datasetIndex].label;
              return `${tooltipItem[0].label} - ${datasetLabel}`;
            },
            label: function (tooltipItem, data) {
              return secondsToFullHours(tooltipItem.value);
            },
          },
        },
        legend: {
          position: "bottom",
          align: "center",
          fullWidth: true,
          labels: {
            fontColor: "#A0A4A9",
            usePointStyle: true,
            fontFamily: "Rubik-Regular",
            padding: 27,
          },
        },
      }}
    />
  );
}

export default SensorPrescriptionTimeTwoBar;
